import React from 'react';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import Calendly from './components/Calendly';
import ShowcaseVideo from "./components/ShowcaseVideo";

function App() {
    return (
        <div className="App">
            <Hero />
            <About />
            {/*<ShowcaseVideo />*/}
            {/*<Calendly />*/}
        </div>
    );
}

export default App;
